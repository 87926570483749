import { ISGWTConnectConfiguration } from '@sgwt/connect-core';

export interface AppConfig {
  sgwt_env: 'homologation' | 'production';
  sgconnect: ISGWTConnectConfiguration;
  helpCenter: {
    contact: string;
    subject: string;
  };
}

export function getConfig(): AppConfig {
  return window.sgmeConfiguration;
}
